/* tslint:disable */
/* eslint-disable */
/* @relayHash 9c326e6298823ec357cde44b9dc3d95a */

import { ConcreteRequest } from 'relay-runtime';
export type AdminDetailsInput = {
  email: string;
  password?: string | null;
  name?: string | null;
  permissions?: Array<AdminPermissionInput | null> | null;
};
export type AdminPermissionInput = {
  id?: string | null;
  admin_id?: number | null;
  entity?: string | null;
  permission?: string | null;
};
export type apiUpdateAdminMutationVariables = {
  id: string;
  details: AdminDetailsInput;
};
export type apiUpdateAdminMutationResponse = {
  readonly updateAdmin: {
    readonly email: string | null;
    readonly name: string | null;
  };
};
export type apiUpdateAdminMutation = {
  readonly response: apiUpdateAdminMutationResponse;
  readonly variables: apiUpdateAdminMutationVariables;
};

/*
mutation apiUpdateAdminMutation(
  $id: ID!
  $details: AdminDetailsInput!
) {
  updateAdmin(id: $id, data: $details) {
    email
    name
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null,
      },
      {
        kind: 'LocalArgument',
        name: 'details',
        type: 'AdminDetailsInput!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'details',
      },
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'email',
      args: null,
      storageKey: null,
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'apiUpdateAdminMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: v0 /*: any*/,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'updateAdmin',
          storageKey: null,
          args: v1 /*: any*/,
          concreteType: 'Admin',
          plural: false,
          selections: [v2 /*: any*/, v3 /*: any*/],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'apiUpdateAdminMutation',
      argumentDefinitions: v0 /*: any*/,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'updateAdmin',
          storageKey: null,
          args: v1 /*: any*/,
          concreteType: 'Admin',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              kind: 'ScalarField',
              alias: null,
              name: 'id',
              args: null,
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'mutation',
      name: 'apiUpdateAdminMutation',
      id: null,
      text:
        'mutation apiUpdateAdminMutation(\n  $id: ID!\n  $details: AdminDetailsInput!\n) {\n  updateAdmin(id: $id, data: $details) {\n    email\n    name\n    id\n  }\n}\n',
      metadata: {},
    },
  };
})();
(node as any).hash = '258faa866470306bb3066e712d2f1dd9';
export default node;
