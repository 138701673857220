/* tslint:disable */
/* eslint-disable */
/* @relayHash 32fc15457859eab731835ab71b0da8cd */

import { ConcreteRequest } from "relay-runtime";
export type apiDeleteCompanyMutationVariables = {
    id: string;
    reverse?: boolean | null;
    anonymize?: boolean | null;
};
export type apiDeleteCompanyMutationResponse = {
    readonly deleteCompany: boolean | null;
};
export type apiDeleteCompanyMutation = {
    readonly response: apiDeleteCompanyMutationResponse;
    readonly variables: apiDeleteCompanyMutationVariables;
};



/*
mutation apiDeleteCompanyMutation(
  $id: ID!
  $reverse: Boolean
  $anonymize: Boolean
) {
  deleteCompany(id: $id, reverse: $reverse, anonymize: $anonymize)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reverse",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "anonymize",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "deleteCompany",
    "args": [
      {
        "kind": "Variable",
        "name": "anonymize",
        "variableName": "anonymize"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "reverse",
        "variableName": "reverse"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiDeleteCompanyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "apiDeleteCompanyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiDeleteCompanyMutation",
    "id": null,
    "text": "mutation apiDeleteCompanyMutation(\n  $id: ID!\n  $reverse: Boolean\n  $anonymize: Boolean\n) {\n  deleteCompany(id: $id, reverse: $reverse, anonymize: $anonymize)\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '082cf733cc8611c6ec7584d884ecb999';
export default node;
