import {
  createApiActionCreators,
  createReducer,
  createActionType,
  RequestActionTypes,
  createActionCreator,
} from '../../helpers/redux/redux-helpers';
import { api } from './api';
import { call, takeLatest, takeEvery, put } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { errorToastActions, successToastActions } from '../toast/ducks';
import { AppState } from '../../helpers/store/models/AppState';
import { push } from 'connected-react-router';
import { FileInterface } from '../../views/components/FileInput/FileInput';
import i18n from '../../i18n';

/* STATE */
export interface DealsTable {
  status: 'pending' | 'success' | 'failed';
  files: FileInterface[];
}

/* ACTION TYPES */
export enum DealActionTypes {
  DeleteDeal = '@@DealManagementDetail/DELETE_DEAL',
  UpdateDeal = '@@DealManagementDetail/UPDATE_DEAL',
  UploadFile = '@@DealFileInput/UPLOAD_FILE',
  SetFiles = '@@DealFileInput/SET_FILES',
}

/* ACTIONS */
export const deleteDealAction = createApiActionCreators(
  DealActionTypes.DeleteDeal,
);

export const updateDealAction = createApiActionCreators(
  DealActionTypes.UpdateDeal,
);

export const uploadFileAction = createApiActionCreators(
  DealActionTypes.UploadFile,
);

export const setFilesAction = createActionCreator(DealActionTypes.SetFiles);

/* REDUCERS */
const initialState = {
  status: '',
  files: [],
};

const status = createReducer(initialState.status, {
  [DealActionTypes.DeleteDeal]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [DealActionTypes.UpdateDeal]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
});

const files = createReducer(initialState.files, {
  [DealActionTypes.UploadFile]: {
    [RequestActionTypes.SUCCESS]: (
      state: FileInterface[],
      payload: FileInterface,
    ) => [...state, payload],
  },
  [DealActionTypes.SetFiles]: (
    _state: FileInterface[],
    payload: FileInterface[],
  ) => payload,
});

export default combineReducers<DealsTable>({
  status,
  files,
});

/* SELECTORS */
export const selectDealState = (state: AppState) => state.dealsTable;

export const selectDealRequestState = (state: AppState) =>
  selectDealState(state).status;

export const selectDealUploadedFiles = (state: AppState) =>
  selectDealState(state).files;

/* SAGAS */
function* deleteDeal({ payload }: any) {
  try {
    const resp = yield call(api.deleteDeal, payload);
    if (resp.ok) {
      yield put(deleteDealAction.success());
      yield put(successToastActions(i18n.t('Actions.Successful')));
    } else {
      yield put(deleteDealAction.failure());
      yield put(errorToastActions(i18n.t('Actions.Failed')));
      console.error('Delete deal failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(deleteDealAction.failure());
    yield put(errorToastActions(i18n.t('Actions.Failed')));
    console.error('Delete deal failed', JSON.stringify(e));
  }
}

function* updateDeal({ payload }: any) {
  try {
    const resp = yield call(api.updateDeal, payload);
    if (resp.ok) {
      yield put(updateDealAction.success());
      yield put(successToastActions(i18n.t('Deals.Update.Successful')));
      yield put(push('/deal'));
    } else {
      yield put(updateDealAction.failure());
      yield put(errorToastActions(i18n.t('Deals.Update.Failed')));
      console.error('Update deal failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(updateDealAction.failure());
    yield put(errorToastActions(i18n.t('Deals.Update.Failed')));
    console.error('Update deal failed', JSON.stringify(e));
  }
}

function* uploadDealAttachment({ payload }: any) {
  try {
    const resp = yield call(api.uploadFile, payload.file);
    const respFile = resp.data?.data?.file;

    const updated = { ...payload.fileToAdd };
    updated.error = resp.data && resp.data.code;

    if (resp.ok && resp.data.ok) {
      updated.url = respFile.url;
      updated.id = respFile.id;

      if (payload.type !== 'avatar') {
        yield put(uploadFileAction.success(updated));
      } else {
        yield put(setFilesAction([updated]));
      }
    } else {
      if (updated.error === 'BAD_FILE_EXT') {
        yield put(errorToastActions(i18n.t('Deals.Files.Saga.Bad meta')));
      } else if (updated.error === 'MAX_FILE_SIZE') {
        yield put(errorToastActions(i18n.t('Deals.Files.Saga.Large')));
      } else {
        yield put(errorToastActions(i18n.t('Deals.Files.Saga.Failed')));
      }
      console.error('File upload failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(errorToastActions(i18n.t('Deals.Files.Saga.Failed')));
    console.error('File upload failed', JSON.stringify(e));
  }
}

/* EXPORT */
export function* dealManagementSaga() {
  yield takeLatest(
    createActionType(DealActionTypes.DeleteDeal, RequestActionTypes.REQUEST),
    deleteDeal,
  );

  yield takeLatest(
    createActionType(DealActionTypes.UpdateDeal, RequestActionTypes.REQUEST),
    updateDeal,
  );

  yield takeEvery(
    createActionType(DealActionTypes.UploadFile, RequestActionTypes.REQUEST),
    uploadDealAttachment,
  );
}
