/* tslint:disable */
/* eslint-disable */
/* @relayHash 4c8eacef99bb3c3091e63982291f5d72 */

import { ConcreteRequest } from "relay-runtime";
export type DealCooperation = "RND_COOPERATION" | "SHARE_ASSETS" | "SHARE_PROCUREMENT" | "SPARE_CAPACITY" | "%future added value";
export type DealType = "OFFER" | "REQUEST" | "%future added value";
export type DealInput = {
    summary: string;
    type: DealType;
    cooperation: DealCooperation;
    category: string;
    description?: string | null;
    isInternal?: boolean | null;
    onlyInternal?: boolean | null;
    keywords?: Array<string> | null;
    partnerRequirements?: string | null;
    attachments?: Array<string> | null;
    expires?: unknown | null;
    video?: string | null;
};
export type apiUpdateDealMutationVariables = {
    id: string;
    data: DealInput;
    lang?: string | null;
};
export type apiUpdateDealMutationResponse = {
    readonly updateDeal: {
        readonly id: string;
    };
};
export type apiUpdateDealMutation = {
    readonly response: apiUpdateDealMutationResponse;
    readonly variables: apiUpdateDealMutationVariables;
};



/*
mutation apiUpdateDealMutation(
  $id: ID!
  $data: DealInput!
  $lang: String
) {
  updateDeal(id: $id, data: $data, lang: $lang) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "data",
    "type": "DealInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lang",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateDeal",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      }
    ],
    "concreteType": "Deal",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiUpdateDealMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "apiUpdateDealMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiUpdateDealMutation",
    "id": null,
    "text": "mutation apiUpdateDealMutation(\n  $id: ID!\n  $data: DealInput!\n  $lang: String\n) {\n  updateDeal(id: $id, data: $data, lang: $lang) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0eecd97ec80d18d73ae2fa197a27130c';
export default node;
