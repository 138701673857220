/* tslint:disable */
/* eslint-disable */
/* @relayHash 804b2f59c9caa74940541713045b268a */

import { ConcreteRequest } from "relay-runtime";
export type apiDeleteUserMutationVariables = {
    id: string;
    reverse?: boolean | null;
    anonymize?: boolean | null;
};
export type apiDeleteUserMutationResponse = {
    readonly deleteUser: boolean;
};
export type apiDeleteUserMutation = {
    readonly response: apiDeleteUserMutationResponse;
    readonly variables: apiDeleteUserMutationVariables;
};



/*
mutation apiDeleteUserMutation(
  $id: ID!
  $reverse: Boolean
  $anonymize: Boolean
) {
  deleteUser(id: $id, reverse: $reverse, anonymize: $anonymize)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reverse",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "anonymize",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "deleteUser",
    "args": [
      {
        "kind": "Variable",
        "name": "anonymize",
        "variableName": "anonymize"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "reverse",
        "variableName": "reverse"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiDeleteUserMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "apiDeleteUserMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiDeleteUserMutation",
    "id": null,
    "text": "mutation apiDeleteUserMutation(\n  $id: ID!\n  $reverse: Boolean\n  $anonymize: Boolean\n) {\n  deleteUser(id: $id, reverse: $reverse, anonymize: $anonymize)\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'fd32d84263d735118aef92c06083a75d';
export default node;
