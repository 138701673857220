import { all, fork } from 'redux-saga/effects';

import { userSaga } from '../../saga/user/ducks';
import { userAdministrationSaga } from '../../saga/userAdministration/ducks';
import { adminManagementSaga } from '../../saga/adminManagement/ducks';
import { companyManagementSaga } from '../../saga/companyManagement/ducks';
import { toastSaga } from '../../saga/toast/ducks';
import { dealManagementSaga } from '../../saga/dealManagement/ducks';

export default function* rootSaga() {
  yield all([
    fork(toastSaga),
    fork(userSaga),
    fork(userAdministrationSaga),
    fork(adminManagementSaga),
    fork(dealManagementSaga),
    fork(companyManagementSaga),
  ]);
}
