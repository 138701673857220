import commitMutationPromise from '../../helpers/gql-api-client';
import { graphql } from 'relay-runtime';
import { DeleteProps } from '../../types/RequestTypes';

export const api = {
  deleteCompany: (payload: DeleteProps) =>
    commitMutationPromise({
      mutation: deleteCompanyMutation,
      variables: {
        id: payload.id,
        reverse: payload.reverse,
        anonymize: payload.anonymize,
      },
    }),
  updateCompany: (payload: any) =>
    commitMutationPromise({
      mutation: updateCompanyMutation,
      variables: {
        details: payload.details,
        lang: payload.lang,
      },
    }),
  createCompany: (payload: any) =>
    commitMutationPromise({
      mutation: createCompanyMutation,
      variables: {
        details: payload.details,
        lang: payload.lang,
      },
    }),
};

const deleteCompanyMutation = graphql`
  mutation apiDeleteCompanyMutation(
    $id: ID!
    $reverse: Boolean
    $anonymize: Boolean
  ) {
    deleteCompany(
      id: $id
      reverse: $reverse
      anonymize: $anonymize
    )
  }
`;

const updateCompanyMutation = graphql`
  mutation apiUpdateMyCompanyMutation($details: CompanyInput!, $lang: String) {
    updateCompany(data: $details, lang: $lang) {
      id
    }
  }
`;

const createCompanyMutation = graphql`
  mutation apiCreateCompanyMutation($details: CompanyInput!, $lang: String) {
    createCompany(data: $details, lang: $lang) {
      id
    }
  }
`;
