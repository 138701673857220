/* tslint:disable */
/* eslint-disable */
/* @relayHash ddeeefd6528894707d63b7382a9cf834 */

import { ConcreteRequest } from "relay-runtime";
export type CompanyInput = {
    id?: string | null;
    owner?: string | null;
    name: string;
    country?: string | null;
    street?: string | null;
    city?: string | null;
    zip?: string | null;
    state?: string | null;
    vat?: string | null;
    logo?: string | null;
    web?: string | null;
    info?: string | null;
    categories: Array<string>;
    keywords: Array<unknown>;
    specialization?: string | null;
    certificates?: string | null;
    gps?: GPSInput | null;
};
export type GPSInput = {
    lng?: number | null;
    lat?: number | null;
};
export type apiUpdateMyCompanyMutationVariables = {
    details: CompanyInput;
    lang?: string | null;
};
export type apiUpdateMyCompanyMutationResponse = {
    readonly updateCompany: {
        readonly id: string;
    };
};
export type apiUpdateMyCompanyMutation = {
    readonly response: apiUpdateMyCompanyMutationResponse;
    readonly variables: apiUpdateMyCompanyMutationVariables;
};



/*
mutation apiUpdateMyCompanyMutation(
  $details: CompanyInput!
  $lang: String
) {
  updateCompany(data: $details, lang: $lang) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "details",
    "type": "CompanyInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lang",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCompany",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "details"
      },
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      }
    ],
    "concreteType": "Company",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiUpdateMyCompanyMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "apiUpdateMyCompanyMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiUpdateMyCompanyMutation",
    "id": null,
    "text": "mutation apiUpdateMyCompanyMutation(\n  $details: CompanyInput!\n  $lang: String\n) {\n  updateCompany(data: $details, lang: $lang) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0347da488018c03a7969c8745bcce96f';
export default node;
