import { graphql } from 'relay-runtime';
import commitMutationPromise from '../../helpers/gql-api-client';
import { apiClient } from '../../helpers/api-client';

export const api = {
  deleteDeal: (payload: any) =>
    commitMutationPromise({
      mutation: deleteDealMutation,
      variables: {
        id: payload.id,
        reverse: payload.reverse,
        anonymize: payload.anonymize,
      },
    }),
  updateDeal: (payload: any) =>
    commitMutationPromise({
      mutation: updateDealMutation,
      variables: {
        id: payload.id,
        data: payload.data,
        lang: payload.lang,
      },
    }),
  uploadFile: (file: any) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return apiClient.post('/api/storage/upload/deal_attachment', formData, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  },
};

const deleteDealMutation = graphql`
  mutation apiDeleteDealMutation(
    $id: ID!
    $reverse: Boolean
    $anonymize: Boolean
  ) {
    deleteDeal(
      id: $id,
      reverse: $reverse
      anonymize: $anonymize
    ) {
      id
    }
  }
`;

const updateDealMutation = graphql`
  mutation apiUpdateDealMutation($id: ID!, $data: DealInput!, $lang: String) {
    updateDeal(id: $id, data: $data, lang: $lang) {
      id
    }
  }
`;
