/* tslint:disable */
/* eslint-disable */
/* @relayHash f84dbf948f41902a87afb45684f93da6 */

import { ConcreteRequest } from 'relay-runtime';
export type AdminDetailsInput = {
  email: string;
  password?: string | null;
  name?: string | null;
  permissions?: Array<AdminPermissionInput | null> | null;
};
export type AdminPermissionInput = {
  id?: string | null;
  admin_id?: number | null;
  entity?: string | null;
  permission?: string | null;
};
export type apiCreateAdminMutationVariables = {
  details: AdminDetailsInput;
};
export type apiCreateAdminMutationResponse = {
  readonly createAdmin: {
    readonly email: string | null;
    readonly name: string | null;
  };
};
export type apiCreateAdminMutation = {
  readonly response: apiCreateAdminMutationResponse;
  readonly variables: apiCreateAdminMutationVariables;
};

/*
mutation apiCreateAdminMutation(
  $details: AdminDetailsInput!
) {
  createAdmin(data: $details) {
    email
    name
    id
  }
}
*/

const node: ConcreteRequest = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'details',
        type: 'AdminDetailsInput!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'data',
        variableName: 'details',
      },
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'email',
      args: null,
      storageKey: null,
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    };
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'apiCreateAdminMutation',
      type: 'Mutation',
      metadata: null,
      argumentDefinitions: v0 /*: any*/,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'createAdmin',
          storageKey: null,
          args: v1 /*: any*/,
          concreteType: 'Admin',
          plural: false,
          selections: [v2 /*: any*/, v3 /*: any*/],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'apiCreateAdminMutation',
      argumentDefinitions: v0 /*: any*/,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'createAdmin',
          storageKey: null,
          args: v1 /*: any*/,
          concreteType: 'Admin',
          plural: false,
          selections: [
            v2 /*: any*/,
            v3 /*: any*/,
            {
              kind: 'ScalarField',
              alias: null,
              name: 'id',
              args: null,
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'mutation',
      name: 'apiCreateAdminMutation',
      id: null,
      text:
        'mutation apiCreateAdminMutation(\n  $details: AdminDetailsInput!\n) {\n  createAdmin(data: $details) {\n    email\n    name\n    id\n  }\n}\n',
      metadata: {},
    },
  };
})();
(node as any).hash = '09b0c7f2779f96e6ccd253e2f5094913';
export default node;
