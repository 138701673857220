import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { persistor, store } from './helpers/store/configure-store';
import { history } from './helpers/store/root-reducer';

import { PersistGate } from 'redux-persist/integration/react';
import ToastMessages from './views/components/ToastMessages/ToastMessages';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function loading() {
  return <div className="animated fadeIn pt-3 text-center">Loading...</div>;
}

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const Login = React.lazy(() => import('./views/pages/Login'));
const PasswordReset = React.lazy(() => import('./views/pages/PasswordReset'));
const RequestPasswordReset = React.lazy(() =>
  import('./views/pages/RequestPasswordReset'),
);
const Page404 = React.lazy(() => import('./views/pages/Page404'));
const Page500 = React.lazy(() => import('./views/pages/Page500'));

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<div />} persistor={persistor}>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={loading()}>
            <ToastMessages />
            <Switch>
              <Route exact path="/login" render={() => <Login />} />
              <Route
                exact
                path="/password-reset"
                render={() => <PasswordReset />}
              />
              <Route
                exact
                path="/reset-password"
                render={() => <RequestPasswordReset />}
              />
              <Route exact path="/404" render={() => <Page404 />} />
              <Route exact path="/500" render={() => <Page500 />} />
              <Route path="/" render={() => <DefaultLayout />} />
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
