import {
  createApiActionCreators,
  createReducer,
  createActionType,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { api } from './api';
import { call, takeLatest, put } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { AppState } from '../../helpers/store/models/AppState';
import { errorToastActions, successToastActions } from '../toast/ducks';
import { push } from 'connected-react-router';
import i18n from '../../i18n';

/* STATE */
export interface CompanyTable {
  status: 'pending' | 'success' | 'failed';
}

/* ACTION TYPES */
export enum CompanyActionTypes {
  DeleteCompany = '@@CompanyManagement/DELETE_COMPANY',
  UpdateCompany = '@@CompanyManagementDetail/UPDATE_COMPANY',
  CreateCompany = '@@CompanyManagementDetail/CREATE_COMPANY',
}

/* ACTIONS */
export const deleteComapnyAction = createApiActionCreators(
  CompanyActionTypes.DeleteCompany,
);

export const updateCompanyAction = createApiActionCreators(
  CompanyActionTypes.UpdateCompany,
);

export const createCompanyAction = createApiActionCreators(
  CompanyActionTypes.CreateCompany,
);

/* REDUCERS */
const initialState = '';

const status = createReducer(initialState, {
  [CompanyActionTypes.DeleteCompany]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [CompanyActionTypes.UpdateCompany]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [CompanyActionTypes.CreateCompany]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
});

export default combineReducers<CompanyTable>({ status: status });

/* SELECTORS */
export const selectCompanyState = (state: AppState) => state.companiesTable;

export const selectCompanyRequestState = (state: AppState) =>
  selectCompanyState(state).status;

/* SAGAS */
function* deleteCompany({ payload }: any) {
  try {
    const resp = yield call(api.deleteCompany, payload);
    if (resp.ok) {
      yield put(deleteComapnyAction.success(payload));
      yield put(successToastActions(i18n.t('Actions.Successful')));
    } else {
      yield put(deleteComapnyAction.failure());
      yield put(errorToastActions(i18n.t('Actions.Failed')));
      console.error('Delete company failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(deleteComapnyAction.failure());
    yield put(errorToastActions(i18n.t('Actions.Failed')));
    console.error('Delete company failed', JSON.stringify(e));
  }
}

function* updateCompany({ payload }: any) {
  try {
    const resp = yield call(api.updateCompany, payload);
    if (resp.ok) {
      yield put(updateCompanyAction.success());
      yield put(successToastActions(i18n.t('Companies.Update.Successful')));
      yield put(push('/company'));
    } else {
      yield put(updateCompanyAction.failure());
      yield put(errorToastActions(i18n.t('Companies.Update.Failed')));
      console.error('Update company failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(updateCompanyAction.failure());
    yield put(errorToastActions(i18n.t('Companies.Update.Failed')));
    console.error('Update company failed', JSON.stringify(e));
  }
}

function* createCompany({ payload }: any) {
  try {
    const resp = yield call(api.createCompany, payload);
    if (resp.ok) {
      yield put(updateCompanyAction.success());
      yield put(successToastActions(i18n.t('Companies.Create.Successful')));
      yield put(push('/company'));
    } else {
      yield put(updateCompanyAction.failure());
      yield put(errorToastActions(i18n.t('Companies.Create.Failed')));
      console.error('Create company failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(updateCompanyAction.failure());
    yield put(errorToastActions(i18n.t('Companies.Create.Failed')));
    console.error('Create company failed', JSON.stringify(e));
  }
}

/* EXPORT */
export function* companyManagementSaga() {
  yield takeLatest(
    createActionType(
      CompanyActionTypes.DeleteCompany,
      RequestActionTypes.REQUEST,
    ),
    deleteCompany,
  );

  yield takeLatest(
    createActionType(
      CompanyActionTypes.UpdateCompany,
      RequestActionTypes.REQUEST,
    ),
    updateCompany,
  );

  yield takeLatest(
    createActionType(
      CompanyActionTypes.CreateCompany,
      RequestActionTypes.REQUEST,
    ),
    createCompany,
  );
}
