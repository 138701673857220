/* tslint:disable */
/* eslint-disable */
/* @relayHash 5b1a8cddb5028ae900e9ae24f89b0066 */

import { ConcreteRequest } from "relay-runtime";
export type MessagingProvider = "SIGNAL" | "SKYPE" | "TELEGRAM" | "VIBER" | "WHATSAPP" | "%future added value";
export type Salutation = "MR" | "MS" | "%future added value";
export type UserDetailsInput = {
    salutation?: Salutation | null;
    firstName: string;
    lastName: string;
    phone?: string | null;
    branch?: string | null;
    workposition?: string | null;
    email: string;
    contactEmail?: string | null;
    messaging: Array<MessageIdentityInput>;
    linkedin?: string | null;
    password?: string | null;
    lang?: string | null;
    verified?: boolean | null;
};
export type MessageIdentityInput = {
    type: MessagingProvider;
    value: string;
};
export type apiUpdateMutationVariables = {
    details: UserDetailsInput;
    id: string;
};
export type apiUpdateMutationResponse = {
    readonly updateUser: {
        readonly email: string;
        readonly lastName: string;
    };
};
export type apiUpdateMutation = {
    readonly response: apiUpdateMutationResponse;
    readonly variables: apiUpdateMutationVariables;
};



/*
mutation apiUpdateMutation(
  $details: UserDetailsInput!
  $id: ID!
) {
  updateUser(data: $details, id: $id) {
    email
    lastName
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "details",
    "type": "UserDetailsInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "data",
    "variableName": "details"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "apiUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateUser",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiUpdateMutation",
    "id": null,
    "text": "mutation apiUpdateMutation(\n  $details: UserDetailsInput!\n  $id: ID!\n) {\n  updateUser(data: $details, id: $id) {\n    email\n    lastName\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'da2a086c98b8a4560d87fc57f1a75b93';
export default node;
