import { routerMiddleware } from 'connected-react-router';
import localForage from 'localforage';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { environment } from '../../environments/environment';

import rootReducer, { history } from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 0,
    storage: localForage,
    whitelist: ['user'],
    debug: environment.debug,
    blacklist: [],
  },
  rootReducer,
);

const store = createStore(
  persistedReducer,
  composeWithDevTools(compose(applyMiddleware(...middlewares))),
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

if (environment.debug) {
  (window as any).store = store;
}

export { store, persistor };
