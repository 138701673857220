import {
  createApiActionCreators,
  createReducer,
  createActionType,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { api } from './api';
import { call, takeLatest, put } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { errorToastActions, successToastActions } from '../toast/ducks';
import { AppState } from '../../helpers/store/models/AppState';
import { push } from 'connected-react-router';
import i18n from '../../i18n';
import { oc } from 'ts-optchain';

/* STATE */
export interface UserTable {
  status: 'pending' | 'success' | 'failed';
}

/* ACTION TYPES */
export enum UserActionTypes {
  DeleteUser = '@@UserAdministration/DELETE_USER',
  UpdateUser = '@@UserAdministrationDetail/UPDATE_USER',
  CreateUsers = '@@UserAdministrationDetail/CREATE_USERS',
}

/* ACTIONS */
export const deleteUserAction = createApiActionCreators(
  UserActionTypes.DeleteUser,
);

export const updateUserAction = createApiActionCreators(
  UserActionTypes.UpdateUser,
);

export const createUsersAction = createApiActionCreators(
  UserActionTypes.CreateUsers,
);

/* REDUCERS */
const initialState = '';

const status = createReducer(initialState, {
  [UserActionTypes.DeleteUser]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [UserActionTypes.UpdateUser]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [UserActionTypes.CreateUsers]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
});

export default combineReducers<UserTable>({ status: status });

/* SELECTORS */
export const selectUserState = (state: AppState) => state.usersTable;

export const selectUserRequestState = (state: AppState) =>
  selectUserState(state).status;

/* SAGAS */
function* deleteUser({ payload }: any) {
  try {
    const resp = yield call(api.deleteUser, payload);
    if (resp.ok) {
      yield put(deleteUserAction.success());
      yield put(successToastActions(i18n.t('Actions.Successful')));
    } else {
      yield put(deleteUserAction.failure());
      yield put(errorToastActions(i18n.t('Actions.Failed')));
      console.error('Delete user failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(deleteUserAction.failure());
    yield put(errorToastActions(i18n.t('Actions.Failed')));
    console.error('Delete user failed', JSON.stringify(e));
  }
}

function* updateUser({ payload }: any) {
  try {
    const resp = yield call(api.updateUser, payload);
    if (resp.ok) {
      yield put(updateUserAction.success());
      yield put(successToastActions(i18n.t('Users.Update.Successful')));
      yield put(push('/user'));
    } else {
      yield put(updateUserAction.failure());
      yield put(errorToastActions(i18n.t('Users.Update.Failed')));
      console.error('Update user failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(updateUserAction.failure());
    yield put(errorToastActions(i18n.t('Users.Update.Failed')));
    console.error('Update user failed', JSON.stringify(e));
  }
}

function* createUsers({ payload }: any) {
  try {
    const resp = yield call(api.createUsers, payload);
    if (resp.ok) {
      yield put(createUsersAction.success());
      yield put(successToastActions(i18n.t('Actions.Successful')));
      yield put(push('/user'));
    } else {
      yield put(createUsersAction.failure());
      if (oc(resp).data.errors[0].message) {
        yield put(errorToastActions(
          resp,
        ));
      } else {
        yield put(errorToastActions(i18n.t('Actions.Failed')));
      }
      console.error('Create users failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(createUsersAction.failure());
    if (oc(e).source.errors[0].message) {
      yield put(errorToastActions(
        e,
      ));
    } else {
      yield put(errorToastActions(i18n.t('Actions.Failed')));
    }
    yield put(errorToastActions(i18n.t('Actions.Failed')));
    console.error('Create users failed', JSON.stringify(e));
  }
}

/* EXPORT */
export function* userAdministrationSaga() {
  yield takeLatest(
    createActionType(UserActionTypes.DeleteUser, RequestActionTypes.REQUEST),
    deleteUser,
  );

  yield takeLatest(
    createActionType(UserActionTypes.UpdateUser, RequestActionTypes.REQUEST),
    updateUser,
  );

  yield takeLatest(
    createActionType(UserActionTypes.CreateUsers, RequestActionTypes.REQUEST),
    createUsers,
  );
}
