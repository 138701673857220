import commitMutationPromise from '../../helpers/gql-api-client';
import { graphql } from 'relay-runtime';
import { Admin } from '../../types/Admin';
import { DeleteProps } from '../../types/RequestTypes';

export const api = {
  deleteAdmin: (payload: DeleteProps) =>
    commitMutationPromise({
      mutation: deleteAdminMutation,
      variables: {
        id: payload.id,
        reverse: payload.reverse,
        anonymize: payload.anonymize,
      },
    }),
  updateAdmin: (payload: { id: string; details: Admin }) =>
    commitMutationPromise({
      mutation: updateAdminMutation,
      variables: {
        id: payload.id,
        details: payload.details,
      },
    }),
  createAdmin: (payload: { details: Admin }) =>
    commitMutationPromise({
      mutation: createAdminMutation,
      variables: {
        details: payload.details,
      },
    }),
};

const deleteAdminMutation = graphql`
  mutation apiDeleteAdminMutation(
    $id: ID!
    $reverse: Boolean
    $anonymize: Boolean
  ) {
    deleteAdmin(
      id: $id
      reverse: $reverse
      anonymize: $anonymize
    )
  }
`;

const updateAdminMutation = graphql`
  mutation apiUpdateAdminMutation($id: ID!, $details: AdminDetailsInput!) {
    updateAdmin(id: $id, data: $details) {
      email
      name
    }
  }
`;

const createAdminMutation = graphql`
  mutation apiCreateAdminMutation($details: AdminDetailsInput!) {
    createAdmin(data: $details) {
      email
      name
    }
  }
`;
