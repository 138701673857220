import { combineReducers } from 'redux';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { AppState } from '../../helpers/store/models/AppState';
import { api } from './api';
import {
  createActionType,
  createApiActionCreators,
  createReducer,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { Admin, Permission } from '../../types/Admin';
import { oc } from 'ts-optchain';
import { errorToastActions, successToastActions } from '../toast/ducks';
import i18n from '../../i18n';

/* STATE */
export interface UserState {
  isLogged: boolean;
  resetUserEmail: string;
  loggedUser: Admin | null;
}

/* ACTION TYPES */
export enum UserActionTypes {
  Login = '@@User/LOGIN',
  Logout = '@@User/LOGOUT',
  SetPassword = '@@User/SET_PASSWORD',
  ResetPassword = '@@User/RESET_PASSWORD',
  GetInfoFromToken = '@@User/GET_INFO_FROM_TOKEN',
  AdminProfile = '@@User/ADMIN_PROFILE',
}

/* ACTIONS */
export const loginActions = createApiActionCreators(UserActionTypes.Login);
export const logoutAction = createApiActionCreators(UserActionTypes.Logout);
export const resetPasswordActions = createApiActionCreators(
  UserActionTypes.ResetPassword,
);
export const setPasswordActions = createApiActionCreators(
  UserActionTypes.SetPassword,
);
export const getInfoFromTokenActions = createApiActionCreators(
  UserActionTypes.GetInfoFromToken,
);

export const adminProfileActions = createApiActionCreators(
  UserActionTypes.AdminProfile,
);

/* REDUCERS */
const initialState: UserState = {
  isLogged: false,
  resetUserEmail: '',
  loggedUser: null,
};

const isLogged = createReducer(initialState.isLogged, {
  [UserActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => true,
  },
  [UserActionTypes.AdminProfile]: {
    [RequestActionTypes.FAILURE]: (_state: boolean) => false,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => false,
  },
});

const loggedUser = createReducer(initialState.loggedUser, {
  [UserActionTypes.Login]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: Admin) => payload,
  },
  [UserActionTypes.Logout]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean) => null,
  },
});

const resetUserEmail = createReducer(initialState.resetUserEmail, {
  [UserActionTypes.GetInfoFromToken]: {
    [RequestActionTypes.SUCCESS]: (_state: boolean, payload: string) => payload,
  },
});

export default combineReducers<UserState>({
  isLogged,
  resetUserEmail,
  loggedUser,
});

/* SELECTORS */
export const selectUserState = (state: AppState) => state.user;

export const selectUser = (state: AppState) =>
  selectUserState(state).loggedUser;

export const selectUserPermissions = (state: AppState) =>
  oc(selectUser(state)).permissions([]);

export const hasUserPermissionForAction = (
  state: AppState,
  entity: 'admin' | 'company' | 'user' | 'deal',
  action: 'read_all' | 'read_one' | 'write' | 'delete',
) =>
  selectUserPermissions(state).some(
    (p: Permission) => p.entity === entity && p.permission === action,
  );

export const selectUserName = (state: AppState) =>
  oc(selectUser(state)).name('');

export const selectIsLogged = (state: AppState) =>
  selectUserState(state).isLogged;

export const selectTokenEmail = (state: AppState) =>
  selectUserState(state).resetUserEmail;

/* SAGAS */
function* login({ payload }: any) {
  const resp = yield call(api.login, payload);

  if (resp.ok) {
    yield put(loginActions.success(resp.data.data.user));
    yield put(successToastActions(i18n.t('Login.Successful')));
    yield put(push('/'));
  } else {
    yield put(errorToastActions(i18n.t('Login.Failed')));
  }
}

function* handleProfile() {
  const resp = yield call(api.adminProfile);
  if (!resp.ok) {
    yield put(adminProfileActions.failure());
    yield put(push('/login'));
  }
}


function* handleLogout({ payload }: any) {
  const resp = yield call(api.logout);
  if (resp.ok) {
    yield put(logoutAction.success());
    yield put(push('/login'));
    if (payload) {
      yield put(successToastActions(i18n.t('Logout.Successful')));
    }
  } else {
    yield put(errorToastActions(i18n.t('Logout.Failed')));
  }
}

function* resetPassword({ payload }: any) {
  const resp = yield call(api.requestResetPassword, payload);
  if (resp.ok && payload.redirect) {
    yield put(push('/'));
  }
}

function* setPassword({ payload }: any) {
  const resp = yield call(api.requestSetPassword, payload);
  if (payload.password === payload.passwordRepeat) {
    if (resp.ok) {
      yield put(successToastActions(i18n.t('Set password.Successful')));
      yield put(push('/'));
    }
  } else {
    yield put(errorToastActions(i18n.t('Set password.Do not match')));
  }
}

function* getInfoFromToken({ payload }: any) {
  const resp = yield call(api.getInfoFromToken, payload);

  if (resp.ok) {
    yield put(getInfoFromTokenActions.success(resp.data.data.email));
  } else {
    yield put(push('/login'));
  }
}

/* EXPORT */
export function* userSaga() {
  yield takeLatest(
    createActionType(UserActionTypes.Login, RequestActionTypes.REQUEST),
    login,
  );

  yield takeLatest(
    createActionType(UserActionTypes.ResetPassword, RequestActionTypes.REQUEST),
    resetPassword,
  );

  yield takeLatest(
    createActionType(UserActionTypes.SetPassword, RequestActionTypes.REQUEST),
    setPassword,
  );

  yield takeLatest(
    createActionType(
      UserActionTypes.GetInfoFromToken,
      RequestActionTypes.REQUEST,
    ),
    getInfoFromToken,
  );

  yield takeLatest(
    createActionType(UserActionTypes.Logout, RequestActionTypes.REQUEST),
    handleLogout,
  );

  yield takeLatest(
    createActionType(UserActionTypes.AdminProfile, RequestActionTypes.REQUEST),
    handleProfile,
  );
}
