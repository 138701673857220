import { combineReducers } from 'redux';

import { AppState } from '../../helpers/store/models/AppState';
import {
  createActionCreator,
  createReducer,
} from '../../helpers/redux/redux-helpers';

/* STATE */
export interface ToastState {
  success: string;
  warn: string;
  error: string;
}

/* ACTION TYPES */
export enum ToastActionTypes {
  SuccessToast = '@@Toast/SUCCESS',
  WarnToast = '@@Toast/WARN',
  ErrorToast = '@@Toast/ERROR',
}

/* ACTIONS */
export const successToastActions = createActionCreator(
  ToastActionTypes.SuccessToast,
);

export const warnToastActions = createActionCreator(ToastActionTypes.WarnToast);

export const errorToastActions = createActionCreator(
  ToastActionTypes.ErrorToast,
);

/* REDUCERS */
const initialState: ToastState = {
  success: '',
  warn: '',
  error: '',
};

const success = createReducer(initialState.success, {
  [ToastActionTypes.SuccessToast]: (state: string, payload: string) => payload,
});

const warn = createReducer(initialState.warn, {
  [ToastActionTypes.WarnToast]: (state: string, payload: string) => payload,
});

const error = createReducer(initialState.error, {
  [ToastActionTypes.ErrorToast]: (state: string, payload: string) => payload,
});

export default combineReducers<ToastState>({
  success,
  warn,
  error,
});

/* SELECTORS */
export const selectToastState = (state: AppState) => state.toast;

export const selectSuccessToast = (state: AppState) =>
  selectToastState(state).success;

export const selectWarnToast = (state: AppState) =>
  selectToastState(state).warn;

export const selectErrorToast = (state: AppState) =>
  selectToastState(state).error;

/* SAGAS */

/* EXPORT */
export function* toastSaga() {}
