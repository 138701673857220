import { graphql } from 'relay-runtime';
import commitMutationPromise from '../../helpers/gql-api-client';
import { DeleteProps } from '../../types/RequestTypes';

export const api = {
  deleteUser: (payload: DeleteProps) =>
    commitMutationPromise({
      mutation: deleteUserMutation,
      variables: {
        id: payload.id,
        reverse: payload.reverse,
        anonymize: payload.anonymize,
      },
    }),
  updateUser: (payload: any) =>
    commitMutationPromise({
      mutation: updateUserMutation,
      variables: {
        id: payload.id,
        details: payload.details,
      },
    }),
  createUsers: (payload: any) => {
    return commitMutationPromise({
      mutation: createUsersMutation,
      variables: {
        emails: payload?.emails,
        lang: payload?.lang,
        type: payload?.type,
        company: payload?.company,
      },
    });
  },
};

const createUsersMutation = graphql`
  mutation apiCreateUsersMutation(
    $emails: String!
    $lang: String!
    $type: String!
    $company: String!
  ) {
    createUsers(
      emails: $emails
      lang: $lang
      type: $type
      company: $company
    )
  }
`;

const deleteUserMutation = graphql`
  mutation apiDeleteUserMutation(
    $id: ID!
    $reverse: Boolean
    $anonymize: Boolean
  ) {
    deleteUser(
      id: $id
      reverse: $reverse
      anonymize: $anonymize
    )
  }
`;

const updateUserMutation = graphql`
  mutation apiUpdateMutation($details: UserDetailsInput!, $id: ID!) {
    updateUser(data: $details, id: $id) {
      email
      lastName
    }
  }
`;

