import React, { useEffect } from 'react';
import { AppState } from '../../../helpers/store/models/AppState';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import {
  errorToastActions,
  selectErrorToast,
  selectSuccessToast,
  selectWarnToast,
  successToastActions,
  warnToastActions,
} from '../../../saga/toast/ducks';

const ToastMessages = (props: DispatchToProps & StateToProps) => {
  useEffect(() => {
    if (props.toastSuccess !== '') {
      toast.success(props.toastSuccess);
      props.setSuccessToast('');
    }
    if (props.toastWarn !== '') {
      toast.warn(props.toastWarn);
      props.setWarnToast('');
    }
    if (props.toastError !== '') {
      toast.error(props.toastError);
      props.setErrorToast('');
    }
  }, [props]);

  return <ToastContainer autoClose={3000} position="top-center" />;
};

interface StateToProps {
  toastSuccess: string;
  toastWarn: string;
  toastError: string;
}

interface DispatchToProps {
  setSuccessToast: (value: string) => void;
  setWarnToast: (value: string) => void;
  setErrorToast: (value: string) => void;
}

const mapStateToProps = (state: AppState): StateToProps => ({
  toastSuccess: selectSuccessToast(state),
  toastWarn: selectWarnToast(state),
  toastError: selectErrorToast(state),
});

const mapDispatchToProps: DispatchToProps = {
  setSuccessToast: successToastActions,
  setWarnToast: warnToastActions,
  setErrorToast: errorToastActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessages);
