/* tslint:disable */
/* eslint-disable */
/* @relayHash 7d1fd90dc8f035b2e6ef47d1129cf10d */

import { ConcreteRequest } from "relay-runtime";
export type apiCreateUsersMutationVariables = {
    emails: string;
    lang: string;
    type: string;
    company: string;
};
export type apiCreateUsersMutationResponse = {
    readonly createUsers: boolean | null;
};
export type apiCreateUsersMutation = {
    readonly response: apiCreateUsersMutationResponse;
    readonly variables: apiCreateUsersMutationVariables;
};



/*
mutation apiCreateUsersMutation(
  $emails: String!
  $lang: String!
  $type: String!
  $company: String!
) {
  createUsers(emails: $emails, lang: $lang, type: $type, company: $company)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "emails",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lang",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "company",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "createUsers",
    "args": [
      {
        "kind": "Variable",
        "name": "company",
        "variableName": "company"
      },
      {
        "kind": "Variable",
        "name": "emails",
        "variableName": "emails"
      },
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiCreateUsersMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "apiCreateUsersMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiCreateUsersMutation",
    "id": null,
    "text": "mutation apiCreateUsersMutation(\n  $emails: String!\n  $lang: String!\n  $type: String!\n  $company: String!\n) {\n  createUsers(emails: $emails, lang: $lang, type: $type, company: $company)\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'dbd441acd4e467ad4bebe5db7756334d';
export default node;
