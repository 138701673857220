import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from '../../saga/user/ducks';
import usersTable from '../../saga/userAdministration/ducks';
import adminsTable from '../../saga/adminManagement/ducks';
import dealsTable from '../../saga/dealManagement/ducks';
import companiesTable from '../../saga/companyManagement/ducks';
import toast from '../../saga/toast/ducks';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME || '/',
});

const rootReducer = combineReducers({
  router: connectRouter(history),
  toast,
  user,
  usersTable,
  adminsTable,
  dealsTable,
  companiesTable,
});

export default (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};
