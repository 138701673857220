import { commitMutation } from 'relay-runtime';
import { normalizeErrorResponse, normalizeSuccessResponse } from './api-client';
import environment from './environment';

export default function commitMutationPromise(options: {
  mutation: any;
  variables: { [val: string]: any };
}): Promise<any> {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      ...options,
      onCompleted: (response: any, errors: any) => {
        if (errors) {
          reject(normalizeErrorResponse(errors));
          return;
        }
        resolve(normalizeSuccessResponse(response));
      },
      onError: (errors) => {
        reject(normalizeErrorResponse(errors));
      },
    });
  });
}
