/* tslint:disable */
/* eslint-disable */
/* @relayHash 3d73285219a5133645e6643812235ac8 */

import { ConcreteRequest } from "relay-runtime";
export type apiDeleteDealMutationVariables = {
    id: string;
    reverse?: boolean | null;
    anonymize?: boolean | null;
};
export type apiDeleteDealMutationResponse = {
    readonly deleteDeal: {
        readonly id: string;
    };
};
export type apiDeleteDealMutation = {
    readonly response: apiDeleteDealMutationResponse;
    readonly variables: apiDeleteDealMutationVariables;
};



/*
mutation apiDeleteDealMutation(
  $id: ID!
  $reverse: Boolean
  $anonymize: Boolean
) {
  deleteDeal(id: $id, reverse: $reverse, anonymize: $anonymize) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reverse",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "anonymize",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteDeal",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "anonymize",
        "variableName": "anonymize"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "reverse",
        "variableName": "reverse"
      }
    ],
    "concreteType": "Deal",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "apiDeleteDealMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "apiDeleteDealMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "apiDeleteDealMutation",
    "id": null,
    "text": "mutation apiDeleteDealMutation(\n  $id: ID!\n  $reverse: Boolean\n  $anonymize: Boolean\n) {\n  deleteDeal(id: $id, reverse: $reverse, anonymize: $anonymize) {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ef7b70e110702631ff498bcf07aa9bc8';
export default node;
