import { apiClient } from '../../helpers/api-client';
import { SetUserPasswordRequest } from '../../types/RequestTypes';

export const api = {
  login: (body: any) =>
    apiClient.post('/api/auth/login', {
      email: body.email,
      password: body.password,
      rememberMe: body.rememberMe,
      origin: true,
    }),

  requestResetPassword: (body: any) =>
    apiClient.post('/api/auth/forgotten', {
      email: body.email,
      origin: body?.origin,
    }),

  requestSetPassword: (body: SetUserPasswordRequest) =>
    apiClient.post(`/api/auth/set-admin-password/${body.token}`, {
      password: body.password,
      password_repeat: body.passwordRepeat,
    }),

  getInfoFromToken: (token: string) =>
    apiClient.get(`/api/auth/verify-admin-token/${token}`),

  logout: () => apiClient.get('/api/auth/logout'),

  adminProfile: () => apiClient.get('/api/auth/admin-profile'),
};
