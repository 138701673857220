import axios from 'axios';
import qs from 'qs';

import { environment } from '../environments/environment';

export const apiClient = axios.create({
  responseType: 'json',
  baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
    process.env.REACT_APP_PORT ? ':' + process.env.REACT_APP_PORT : ''
  }`,
  withCredentials: true,
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
});

apiClient.interceptors.request.use((config) => {
  config.headers.common[
    'Authorization'
  ] = `Basic ${environment.basicAuthToken}`;
  config.headers.common['X-Requested-With'] = `XMLHttpRequest`;
  config.headers.common['Cache-Control'] = 'no-cache';

  return config;
});

apiClient.interceptors.response.use(
  (response) => normalizeSuccessResponse(response),
  (error) => normalizeErrorResponse(error),
);

export function normalizeSuccessResponse(response: any) {
  return {
    ...response,
    ok: true,
  };
}

export function normalizeErrorResponse(error: any) {
  return {
    ...error,
    ok: false,
  };
}
