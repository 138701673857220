import {
  createApiActionCreators,
  createReducer,
  createActionType,
  RequestActionTypes,
} from '../../helpers/redux/redux-helpers';
import { api } from './api';
import { call, takeLatest, put } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { AppState } from '../../helpers/store/models/AppState';
import { errorToastActions, successToastActions } from '../toast/ducks';
import { push } from 'connected-react-router';
import i18n from '../../i18n';
import { oc } from 'ts-optchain';

/* STATE */
export interface AdminTable {
  status: 'pending' | 'success' | 'failed';
}

/* ACTION TYPES */
export enum UserActionTypes {
  DeleteAdmin = '@@AdminManagement/DELETE_ADMIN',
  UpdateAdmin = '@@AdminManagementDetail/UPDATE_ADMIN',
  CreateAdmin = '@@AdminManagementDetail/CREATE_ADMIN',
}

/* ACTIONS */
export const deleteAdminAction = createApiActionCreators(
  UserActionTypes.DeleteAdmin,
);

export const updateAdminAction = createApiActionCreators(
  UserActionTypes.UpdateAdmin,
);

export const createAdminAction = createApiActionCreators(
  UserActionTypes.CreateAdmin,
);

/* REDUCERS */
const initialState = '';

const status = createReducer(initialState, {
  [UserActionTypes.DeleteAdmin]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [UserActionTypes.UpdateAdmin]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
  [UserActionTypes.CreateAdmin]: {
    [RequestActionTypes.FAILURE]: (_state: string) => 'error',
    [RequestActionTypes.SUCCESS]: (_state: string) => 'success',
    [RequestActionTypes.REQUEST]: (_state: string) => 'pending',
  },
});

export default combineReducers<AdminTable>({ status: status });

/* SELECTORS */
export const selectAdminState = (state: AppState) => state.adminsTable;

export const selectAdminRequestState = (state: AppState) =>
  selectAdminState(state).status;

/* SAGAS */
function* deleteAdmin({ payload }: any) {
  try {
    const resp = yield call(api.deleteAdmin, payload);
    if (resp.ok) {
      yield put(deleteAdminAction.success());
      yield put(successToastActions(i18n.t('Admins.Delete.Successful')));
    } else {
      yield put(deleteAdminAction.failure());
      yield put(errorToastActions(i18n.t('Admins.Delete.Failed')));
      console.error('Delete admin failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(deleteAdminAction.failure());
    yield put(errorToastActions(i18n.t('Admins.Delete.Failed')));
    console.error('Delete admin failed', JSON.stringify(e));
  }
}

function* updateAdmin({ payload }: any) {
  try {
    const resp = yield call(api.updateAdmin, payload);
    if (resp.ok) {
      yield put(updateAdminAction.success());
      yield put(successToastActions(i18n.t('Admins.Update.Successful')));
      yield put(push('/admin'));
    } else {
      yield put(updateAdminAction.failure());
      yield put(errorToastActions(i18n.t('Admins.Update.Failed')));
      console.error('Update admin failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(updateAdminAction.failure());
    yield put(errorToastActions(i18n.t('Admins.Update.Failed')));
    console.error('Update admin failed', JSON.stringify(e));
  }
}

function* createAdmin({ payload }: any) {
  try {
    const resp = yield call(api.createAdmin, payload);
    if (resp.ok) {
      yield put(createAdminAction.success());
      yield put(successToastActions(i18n.t('Admins.Create.Successful')));
      yield put(push('/admin'));
    } else {
      yield put(createAdminAction.failure());
      if (oc(resp).data.errors[0].message) {
        yield put(errorToastActions(
          i18n.t(
            'Admins.' +
            resp.data.errors[0].message.replace('.', ''),
          ),
        ));
      } else {
        yield put(errorToastActions(i18n.t('Admins.Create.Failed')));
      }
      console.error('Create admin failed', JSON.stringify(resp));
    }
  } catch (e) {
    yield put(createAdminAction.failure());
    if (oc(e).source.errors[0].message) {
      yield put(errorToastActions(
        i18n.t(
          'Admins.' +
          e.source.errors[0].message.replace('.', ''),
        ),
      ));
    } else {
      yield put(errorToastActions(i18n.t('Admins.Create.Failed')));
    }
    yield put(errorToastActions(i18n.t('Admins.Create.Failed')));
    console.error('Create admin failed', JSON.stringify(e));
  }
}

/* EXPORT */
export function* adminManagementSaga() {
  yield takeLatest(
    createActionType(UserActionTypes.DeleteAdmin, RequestActionTypes.REQUEST),
    deleteAdmin,
  );

  yield takeLatest(
    createActionType(UserActionTypes.UpdateAdmin, RequestActionTypes.REQUEST),
    updateAdmin,
  );

  yield takeLatest(
    createActionType(UserActionTypes.CreateAdmin, RequestActionTypes.REQUEST),
    createAdmin,
  );
}
