import { Environment, Network, RecordSource, Store } from 'relay-runtime';
const store = new Store(new RecordSource());

const network = Network.create((operation, variables) => {
  const url = `${process.env.REACT_APP_PROTOCOL}://${
    process.env.REACT_APP_HOST
  }${process.env.REACT_APP_PORT ? ':' + process.env.REACT_APP_PORT : ''}${
    process.env.REACT_APP_GRAPHQL_API
  }`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': ``,
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => {
    return response.json();
  });
});

const environment = new Environment({
  network,
  store,
});

export default environment;
